import styled from "styled-components"
import Column from "../../ui/column"
import { useNavigate, useSearchParams } from "react-router-dom"
import useRequest from "../../../hooks/use-request"
import { FormEvent, useCallback, useContext, useEffect, useState } from "react"
import Tabs from "../../ui/tabs"
import TextField from "../../ui/text-field"
import Button from "../../ui/button"
import AppContext from "../../../context/app-context"

const Wrapper = styled.div`
  background-color: #1F2BA0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenterBox = styled.div`
  width: 400px;
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  color: white;
  user-select: none
`

const Paragraph = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  color: white;
`

interface TestPasswordProps {
  customColor?: string;
}

const TestPassword = styled.p<TestPasswordProps>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ customColor }) => customColor};
`

const StyledForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RecoverPasswordPage = () => {
  const [searchParams] = useSearchParams()
  const request = useRequest()
  const navigate = useNavigate()
  const token = searchParams.get('token')
  const [validationToken, setValidationToken] = useState<boolean | null>(null)
  const [password, setPassword] = useState<string | null>("")
  const [confirmPassword, setConfirmPassword] = useState<string | null>("")
  const [validPassword, setValidPassword] = useState<boolean>(false)
  const [passwordLength, setPasswordLength] = useState<boolean>(false)
  const [passwordMixedCase, setPasswordMixedCase] = useState<boolean>(false)
  const [passwordAlphanumeric, setPasswordAlphanumeric] = useState<boolean>(false)
  const [loading, setLoading] = useState<string>("")
  const { onModalMessage } = useContext(AppContext)

  const handleChangePassword = (value: string | null) => {
    setPassword(value)

    if (value) {
      let regex = /^.{8,}$/
      if (regex.test(value)) {
        setPasswordLength(true)
      } else {
        setPasswordLength(false)
      }

      regex = /^(?=.*[a-z])(?=.*[A-Z]).+$/
      if (regex.test(value)) {
        setPasswordMixedCase(true)
      } else {
        setPasswordMixedCase(false)
      }

      regex = /^(?=.*[a-zA-Z])(?=.*\d).+$/
      if (regex.test(value)) {
        setPasswordAlphanumeric(true)
      } else {
        setPasswordAlphanumeric(false)
      }
    }

  }

  const handleChangeConfirmPassword = (value: string | null) => {
    setConfirmPassword(value)

    if (value) {
      if (value === password) {
        setValidPassword(true)
      } else {
        setValidPassword(false)
      }
    }

  }

  const fetchDataConfirmToken = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await request<{}>({
        method: "POST",
        url: "/v1/auth/recover-password/validate",
        publicRequest: true,
        body: {
          "token": token
        }
      })
      setValidationToken(true)
    } catch (e) {
      setValidationToken(false)
    }
  }, [request, token])

  useEffect(() => { fetchDataConfirmToken() }, [fetchDataConfirmToken])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading("Enviando...")
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await request<{}>({
        method: "POST",
        url: "/v1/auth/recover-password/update",
        publicRequest: true,
        body: {
          "token": token,
          "password": password
        }
      })
      onModalMessage("Senha alterada com sucesso!")
    } catch (e) {
      onModalMessage(`Erro: tente novamente ou entre em contato com o suporte.`)
    } finally {
      navigate("/")
    }
  }

  return (
    <Wrapper>
      <CenterBox>
        <Column>
          <Title>
            Argo
          </Title>
          <Tabs
            invertColors
            options={[
              { id: "recuperar-senha:", name: "Recuperação de senha:" },
            ]}
          />
          {
            validationToken === null ?
              (
                <Paragraph>Carregando...</Paragraph>
              ) : (
                validationToken ?
                  (
                    <StyledForm onSubmit={onSubmit}>
                      <TextField
                        invertColors
                        label="Digite sua nova senha"
                        placeholder="Senha"
                        type="password"
                        value={password}
                        onChange={handleChangePassword}
                      />
                      <div style={{ marginBottom: "10px" }}>
                        <TestPassword customColor={passwordLength ? "white" : "#FF4D4D"}>Possuir 8 caracteres</TestPassword>
                        <TestPassword customColor={passwordMixedCase ? "white" : "#FF4D4D"}>Possuir letras maiúsculas e minúsculas</TestPassword>
                        <TestPassword customColor={passwordAlphanumeric ? "white" : "#FF4D4D"}>Possuir letras e números</TestPassword>
                      </div>
                      <TextField
                        invertColors
                        label="Confirme sua senha"
                        placeholder="Confirme a senha"
                        type="password"
                        value={confirmPassword}
                        onChange={handleChangeConfirmPassword}
                      />
                      <div>
                        <TestPassword customColor={validPassword ? "white" : "#FF4D4D"}>As senhas devem ser iguais</TestPassword>
                      </div>
                      <Button disabled={!validPassword} type="submit" width={160}>
                        Solicitar
                      </Button>
                      <Paragraph>
                        {loading}
                      </Paragraph>
                    </StyledForm>
                  ) :
                  (
                    <Paragraph>Erro: Token inválido</Paragraph>
                  )
              )
          }
        </Column>
      </CenterBox>
    </Wrapper>
  )
}

export default RecoverPasswordPage